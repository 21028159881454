exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contentfry-jsx": () => import("./../../../src/pages/contentfry.jsx" /* webpackChunkName: "component---src-pages-contentfry-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-studio-jsx": () => import("./../../../src/pages/studio.jsx" /* webpackChunkName: "component---src-pages-studio-jsx" */),
  "component---src-pages-uhuu-jsx": () => import("./../../../src/pages/uhuu.jsx" /* webpackChunkName: "component---src-pages-uhuu-jsx" */)
}

